import type { ListCardProps } from ".";

function getDefaultProps(): ListCardProps {
  return {
    title: "some awesome list",
    href: "/some_list",
    hasMore: true,
    tracks: [
      {
        id: "1",
        title: "Item 1",
        artist: "Artist 1",
        href: "/1",
      },
      {
        id: "2",
        title: "Item 2",
        artist: "Artist 2",
        href: "/2",
      },
      {
        id: "3",
        title: "Item 3",
        artist: "Artist 3",
        href: "/3",
      },
    ],
  };
}

export const listCardFixtures = {
  getDefaultProps,
};
