import { Link } from "@remix-run/react";

export type NamingCardProps = {
  readonly title: string;
  readonly href: string;
  readonly chordProgressions: {
    readonly text: string;
    readonly href: string;
  }[];
  readonly hasMore: boolean;
};

export function NamingCard({
  title,
  href,
  chordProgressions,
  hasMore,
}: NamingCardProps): JSX.Element {
  return (
    <div>
      <h2 className="appLink flex justify-center text-lg font-bold">
        <Link to={href}>{title}</Link>
      </h2>
      <ul className="mt-4 px-2 text-center">
        {chordProgressions.map((progression) => (
          <li key={progression.text}>
            <Link to={progression.href} className="appLink">
              {progression.text}
            </Link>
          </li>
        ))}
        {!!hasMore && <li>...</li>}
      </ul>
    </div>
  );
}
