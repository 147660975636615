import { useEffect, useReducer } from "react";
import { useLoaderData, useNavigate } from "@remix-run/react";
import { apiClient } from "../_apiClient";
import { getTrackListPagePath } from "../tracks._index/path";
import { View } from "./View";
import type { Loader } from "./loader";
export { loader } from "./loader";
export { meta } from "./meta";
// import { countryLinksFixtures } from "./View/CountryLinks/fixtures";
import { routeReducer, getRouteReducerInitialState } from "./route.reducer";
import { useSiteHeaderProps } from "~/components/common/SiteHeader/useProps";
import { useSiteMenuLinksProps } from "~/components/common/SiteMenuLinks/useProps";
import { useRootLoaderData } from "~/root.util";
import { chordProgressionCardFixtures } from "~/components/common/ChordProgressionCard/fixtures";
import { namingCardFixtures } from "~/components/common/MasonryElementsShowcase/cards/NamingCard/fixtures";
import { listCardFixtures } from "~/components/common/MasonryElementsShowcase/cards/ListCard/fixtures";
import { _devLog } from "~/libs/log/_devLog";

// TODO: その前にエラー出たら真っ白になる現象の対応したほうがいいな。エラーバウンダリ無い？
export default function Route(): JSX.Element {
  const pageLoaderData = useLoaderData<Loader>();
  const rootLoaderData = useRootLoaderData();
  const navigate = useNavigate();

  const [routeState, dispatchRouteAction] = useReducer(
    routeReducer,
    undefined,
    getRouteReducerInitialState,
  );

  // TODO: サンプルなので消す
  useEffect(() => {
    setTimeout(() => {
      apiClient.albums._.get({ artistId: "1" }).then((res) => {
        if (res._success) {
          console.log(res._success);
        } else {
          console.log(res._errors);
        }
      });
      apiClient.albums._.post({
        artistId: "1",
        albumName: "album1",
      })
        .then((res) => {
          if (res._success) {
            console.log(res._success);
          } else {
            console.log(res._errors);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 3000);
  }, []);

  const handleSubmitSearchByArtistAlbumTrackForm = (input: string) => {
    _devLog("handleSubmitSearchByArtistAlbumTrackForm", input);

    dispatchRouteAction({
      type: "searchByArtistAlbumTrackForm/submit",
    });
    navigate(
      getTrackListPagePath({
        searchCondition: {
          searchType: "free_input",
          q: input,
        },
      }),
    );
  };

  return (
    <View
      siteHeaderProps={useSiteHeaderProps({
        isLogined: !!rootLoaderData.loginedUserId,
      })}
      siteMenuLinksProps={useSiteMenuLinksProps({
        selectedMenu: "home",
        initialSelectedThemeId: rootLoaderData.themeId,
        isLogined: !!rootLoaderData.loginedUserId,
      })}
      isLogined={!!rootLoaderData.loginedUserId}
      chordProgressionCardPropsList={[
        chordProgressionCardFixtures.getDefaultProps(),
        chordProgressionCardFixtures.getDefaultProps2(),
        chordProgressionCardFixtures.getDefaultProps(),
        chordProgressionCardFixtures.getDefaultProps2(),
        chordProgressionCardFixtures.getDefaultProps(),
        chordProgressionCardFixtures.getDefaultProps2(),
      ]}
      listCardPropsList={[
        listCardFixtures.getDefaultProps(),
        listCardFixtures.getDefaultProps(),
        listCardFixtures.getDefaultProps(),
        listCardFixtures.getDefaultProps(),
        listCardFixtures.getDefaultProps(),
        listCardFixtures.getDefaultProps(),
        listCardFixtures.getDefaultProps(),
        listCardFixtures.getDefaultProps(),
      ]}
      namingCardPropsList={[
        namingCardFixtures.getDefaultProps(),
        namingCardFixtures.getDefaultProps(),
        namingCardFixtures.getDefaultProps(),
        namingCardFixtures.getDefaultProps(),
        namingCardFixtures.getDefaultProps(),
        namingCardFixtures.getDefaultProps(),
        namingCardFixtures.getDefaultProps(),
        namingCardFixtures.getDefaultProps(),
        namingCardFixtures.getDefaultProps(),
        namingCardFixtures.getDefaultProps(),
      ]}
      // countryLinksProps={countryLinksFixtures.getDefaultProps()}
      searchByArtistAlbumTrackFormProps={{
        onSubmit: handleSubmitSearchByArtistAlbumTrackForm,
        isLoading: routeState.isSearchByArtistAlbumTrackFormSubmitting,
      }}
    />
  );
}
