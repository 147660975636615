import { exhaustiveGuard } from "~/libs/exhaustiveGuard";

type RouteState = {
  isSearchByArtistAlbumTrackFormSubmitting: boolean;
};

export function getRouteReducerInitialState(): RouteState {
  return {
    isSearchByArtistAlbumTrackFormSubmitting: false,
  };
}

type RouteAction = {
  type: "searchByArtistAlbumTrackForm/submit";
};

export function routeReducer(
  state: RouteState,
  action: RouteAction,
): RouteState {
  switch (action.type) {
    case "searchByArtistAlbumTrackForm/submit": {
      return {
        ...state,
        isSearchByArtistAlbumTrackFormSubmitting: true,
      };
    }
    default: {
      exhaustiveGuard(action.type);
      return state;
    }
  }
}
