import { useRef } from "react";
import { Icons } from "~/components/common/_icons";
import { LoadingButton } from "~/components/common/LoadingButton";
import { Input } from "~/components/shadcnui/ui/input";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";

export type SearchByArtistAlbumTrackFormProps = {
  readonly onSubmit: (input: string) => void;
  readonly isLoading: boolean;
};

export function SearchByArtistAlbumTrackForm({
  onSubmit,
  isLoading,
}: SearchByArtistAlbumTrackFormProps): JSX.Element {
  const dict = useDictionary();
  const inputRef = useRef<string>("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(inputRef.current);
      }}
      className="flex flex-col items-center"
    >
      <Input
        className="h-12 max-w-[600px]"
        onChange={(e) => {
          inputRef.current = e.target.value;
        }}
      />
      <LoadingButton
        type="submit"
        className="mt-4 flex gap-2"
        isLoading={isLoading}
        icon={<Icons.Crud.Search size={16} />}
      >
        <span>{dict.search}</span>
      </LoadingButton>
    </form>
  );
}
