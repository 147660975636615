import type { NamingCardProps } from ".";

function getDefaultProps(): NamingCardProps {
  return {
    title: "some awesome naming",
    href: "/some_naming",
    hasMore: true,
    chordProgressions: [
      {
        text: "I - IV - V",
        href: "/1",
      },
      {
        text: "ii - V - I",
        href: "/2",
      },
      {
        text: "I - vi - IV - V",
        href: "/3",
      },
    ],
  };
}

export const namingCardFixtures = {
  getDefaultProps,
};
