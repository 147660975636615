import { clsx } from "clsx";

export type HorizontalListProps = {
  readonly items: React.ReactNode;
  readonly className?: string;
};

export function HorizontalList({
  items,
  className,
}: HorizontalListProps): JSX.Element {
  return (
    <div className={clsx("flex overflow-x-scroll", className)}>{items}</div>
  );
}
