import { Link } from "@remix-run/react";

export type HorizontalListLayoutProps = {
  readonly title: string;
  readonly icon: JSX.Element;
  readonly titleIconHref: string;
  readonly listElem: JSX.Element;
};

export function HorizontalListLayout({
  title,
  icon,
  titleIconHref,
  listElem,
}: HorizontalListLayoutProps): JSX.Element {
  return (
    <div className="flex gap-2 border-b-2">
      <Link
        to={titleIconHref}
        className="mt-4 flex h-fit min-w-20 flex-col items-center rounded-full p-2 sm:min-w-24 md:min-w-32"
      >
        <div>{icon}</div>
        <h3 className="mt-2 text-center text-xs font-bold underline hover:text-foreground/80 md:text-sm">
          {title}
        </h3>
      </Link>
      <div className="relative w-full overflow-scroll">
        {listElem}
        <div className="pointer-events-none absolute bottom-0 h-10 w-full bg-gradient-to-t from-background" />
      </div>
    </div>
  );
}
