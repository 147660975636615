import { Link } from "@remix-run/react";

export type ListCardProps = {
  readonly title: string;
  readonly href: string;
  readonly tracks: {
    id: string;
    title: string;
    artist: string;
    href: string;
  }[];
  readonly hasMore: boolean;
};

export function ListCard({
  title,
  href,
  tracks,
  hasMore,
}: ListCardProps): JSX.Element {
  return (
    <div>
      <h2 className="appLink flex justify-center text-lg font-bold">
        <Link to={href}>{title}</Link>
      </h2>
      <ul className="mt-4 px-2 text-center">
        {tracks.map((track) => (
          <li key={track.id}>
            <Link to={track.href} className="appLink">
              {track.artist} - {track.title}
            </Link>
          </li>
        ))}
        {!!hasMore && <li>...</li>}
      </ul>
    </div>
  );
}
