/* eslint-disable react/jsx-max-depth */
import { Link } from "@remix-run/react";
import { PREMIUM_USER_USD_PRICE } from "~/libs/domain/pro/PREMIUM_USER_USD_PRICE";
import { useDictionary } from "~/libs/i18n/context/DictionaryProvider";
import { getAuthLoginPagePath } from "~/routes/auth.login/path";
import { getAuthSignUpPagePath } from "~/routes/auth.sign_up._index/path";

export type RegisterInstructionProps = {};

export function RegisterInstruction(): JSX.Element {
  const dict = useDictionary();
  return (
    <div className="flex w-full flex-col items-center gap-5 bg-primary/10 py-8">
      <div className="text-2xl font-semibold">
        {dict.create_x(dict.account)}
      </div>

      <div className="whitespace-pre-wrap px-8 text-center">
        {dict.create_account_description}
      </div>

      <div className="mt-4 flex flex-col items-center gap-8 md:flex-row">
        <div className="flex flex-col items-center">
          <div>{dict.sign_up}</div>
          <div className="flex rounded-lg border border-primary bg-background">
            <Link
              to={getAuthSignUpPagePath({
                queryParams: {},
              })}
              className="flex flex-col items-center justify-center rounded-l-lg p-5 text-center font-semibold hover:bg-primary-50"
            >
              {dict.as_a_free_user}
            </Link>
            <div className="my-2 border border-primary" />
            <Link
              to={getAuthSignUpPagePath({
                queryParams: {
                  redirectToAfterRegistered: "upgrade",
                },
              })}
              className="flex flex-col items-center justify-center rounded-r-lg p-5 text-center hover:bg-primary-50"
            >
              <span className="font-semibold">{dict.as_a_premium_user}</span>
              <div>
                (<span className="">$</span>
                <span className="text-lg">{PREMIUM_USER_USD_PRICE}</span>
                <span className="ml-1 text-sm">/ {dict.perpetual_license}</span>
                )
              </div>
            </Link>
          </div>
        </div>

        <div>{dict.or}</div>

        <div className="text-center">
          <div className="text-xs">{dict.already_registered_question}</div>
          <Link to={getAuthLoginPagePath()} className="appLink">
            {dict.login}
          </Link>
        </div>
      </div>
    </div>
  );
}
