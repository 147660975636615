import type { MetaFunction } from "@remix-run/react";
import { APP_NAME } from "~/libs/APP_NAME";

export const meta: MetaFunction = () => {
  return [
    { title: APP_NAME },
    {
      name: "description",
      content: "TODO", // TODO
    },
  ];
};
